<template>
  <div class="container">
    <v-btn @click="backMenu"><v-icon>mdi-google-maps</v-icon></v-btn>
    <v-btn @click="backMenu"
      ><v-icon>mdi-magnify</v-icon>Adresse</v-btn
    >
    <div :class="spinner">
      <div id="spinner"></div>
    </div>
    <gmaps-map style="width: 100%; height: 400px" :options="mapOptions">
      <gmaps-marker
        v-for="(item, i) in items"
        :key="i"
        @click="markerShow(item)"
        :icon="item.icon"
        :options="item.options"
      />
    </gmaps-map>
  </div>
</template>

<script>
import { gmapsMap, gmapsMarker } from "x5-gmaps";

export default {
  components: { gmapsMap, gmapsMarker },
  data: () => ({
    url: `https://api.jcdecaux.com/vls/v1/stations?contract=Toulouse&apiKey=2d1f25a04b1332384f22749550e8d57cf2430dec`,
    station: [],
    stations: [],
    mapOptions: {
      center: { lat: 43.608951960496405, lng: 1.441003598726198 },
      zoom: 18,
    },
    //spinner
    spinner:"spinner",
    items: [
      {
        icon: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
        options: { position: { lat: "", lng: "" }, data: [] },
      },
    ],
  }),
  mounted() {
    let opts = {
 lines: 13,
 length: 28,
 width: 14,
 radius: 42,
 scale: 1,
 corners: 1,
 color: '#FFF',
 opacity: 0.25,
 rotate: 0,
 direction: 1,
 speed: 1,
 trail: 60,
 fps: 20,
 zIndex: 2e9,
 className: 'spinner',
 top: '50%',
 left: '50%',
 shadow: false,
 hwaccel: false,
 position: 'absolute',
};
let target = document.getElementById('spinner');
let spinner = new Spinner(opts).spin(target);





    console.log( this.$route.params.id )
    if (this.$route.params.id == "geolocated"){
      this.geolacated();
    }
    else if (this.$store.state.addressGeolocate.latitude !== undefined){
      this.mapOptions.center.lat = this.$store.state.addressGeolocate.latitude
      this.mapOptions.center.lng = this.$store.state.addressGeolocate.longitude
    }
    setTimeout(()=>{this.spinner = "displayNone" },3000  )

    this.showMarkers();
  },
  methods: {
    geolacated() {
      if (navigator.geolocation) {
        console.log("geolocate");
        navigator.geolocation.getCurrentPosition((pos) => {
          console.log(pos.coords.latitude);
          console.log(this.mapOptions);
          this.mapOptions.center.lat = pos.coords.latitude;
          this.mapOptions.center.lng = pos.coords.longitude;
        });
      }
    },
  //montrent les différents marqueur
    markerShow(item) {
      this.$store.dispatch("getDataVelo", item);
      this.station = this.$store.state.dataVelo;
      console.log(this.station);
      this.$router.push("./markerinfo");
    },
    showMarkers() {
      axios.get(this.url).then((response) => {
        console.log(response.data);
        console.log("card");
        this.stations = response.data;
        this.markers();
        console.log(this.items);
      });
       
    },
    markers() {
      this.stations.forEach((data) => {
        //si 0 vélo
        if (data.available_bikes == 0) {
          this.items.push({
            icon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
            options: {
              position: {
                lat: data.position.lat,
                lng: data.position.lng,
              },
            },
            data: data,
          });
        } else if (data.available_bikes > 3) {
          this.items.push({
            icon: "http://maps.google.com/mapfiles/ms/icons/orange-dot.png",
            options: {
              position: {
                lat: data.position.lat,
                lng: data.position.lng,
              },
            },
            data: data,
          });
        } else {
          this.items.push({
            icon: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
            options: {
              position: {
                lat: data.position.lat,
                lng: data.position.lng,
              },
            },
            data: data,
          });
        }
      });
    },
    backMenu() {
       this.$router.push("./confirmationVelo");
    },
  },
};
</script>
<style scoped>
h6 {
  z-index: 122222222222222222222222222222;
  position: absolute;
  top: 2px;
  left: 2px;
}

.spinner {
  content:" ";
  position:absolute;
  top:0px;
  left:0px;
  width:100%;
  height:100%;
  background-color: teal;
  z-index: 222222222222222222222222222222222222222222222222222;
  animation:opa 2s forwards;
}

.displayNone {
  display: none;
}

@keyframes opa {
  0% {
    opacity:1
  }
  80%{
    opacity: 1;
  }
  100%{
    opacity:0
  }
}

</style>