<template>
  <div class="home">
    <Carrousselhome/>
    
  </div>
</template>

<script>
import Carrousselhome from '../components/caroussel/Carrousselhome';

export default {
  name: 'Help',
  components: {
    
    Carrousselhome
}}
</script>

<style>

</style>