<template>
      <transition name="fade">
   <v-card
   
    class="v-card-velo-container"
    max-width="344"
    outlined
    
  >
    <v-list-item class="v-card-velo white--text" three-line>
      <v-list-item-content class="white--text">
        <div class="overline mb-4">
          {{data.data.name}}
        </div>
        <v-list-item-title class="headline mb-1">
           {{data.data.name}}
        </v-list-item-title>
        <v-list-item-subtitle>Il y a {{data.data.available_bikes}} vélos de disponible a cette station</v-list-item-subtitle>
      </v-list-item-content>

    </v-list-item>

    <v-card-actions>
      <v-btn class="white teal--text"
        outlined
        rounded
        text
      >
        <a :href="btnGoogle.href"> Aller a cette Station</a>
      </v-btn>
    </v-card-actions>
    <v-card-actions>
      <v-btn class="white teal--text"
        outlined
        rounded
        text
        @click="backMap"
      >
        Retour Carte
      </v-btn>
    </v-card-actions>
  </v-card>
      </transition>
</template>

<script>
export default {
    data(){
        return {
            data : [],
            btnGoogle:{
              href:""
            }

        }
    },
    mounted(){
        this.data = this.$store.state.dataVelo
        console.log(this.data.data.address)
        this.googleNaming()
    },
    methods:{
        backMap(){
            this.$router.push('./map')
        },
         googleNaming() {
        let googleAdressDepart = this.data.data.address + " TOULOUSE"
        console.log(googleAdressDepart);
        var googleDepart = googleAdressDepart.replace(/ /g, "+");
        this.btnGoogle.href = `https://www.google.fr/maps/dir//${googleDepart}`;
        
    }
    }
}
</script>

<style>
.v-card-velo-container {
      margin-left: 20px!important;
    margin-right: 20px!important;
  margin-top: 20px;
    background-color: teal!important;
    border: 1px solid teal!important;
    color: white!important;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>