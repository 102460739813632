<template>
      
        <Map @mapclick="showMarkerinfo"/>
        
</template>

<script>
import Map from '../components/googleMaps/Map'

  export default {
    components:{
    Map       
  }
,
    data: () => ({
     
      model: 1,


    }),
  methods:{

    showMarkerinfo(data){
      console.log("showmarkerInfo")
    this.map = false;
      this.item = data
    }
    }
  }
</script>



<style>
.v-card-velo {
  padding:20px
}

</style>