<template>
  <div class="searchMapDiv">
      <div v-if="searchBtn == true">

      
    <v-card  class="searcMapVcard">
      <v-card-title class="text-h5 white--text">Recherche dans la carte</v-card-title>
      <v-card-text class="white--text"
        >Rentrer l'adresse de la destination que vous désirez vous rendre ou
        afficher la carte</v-card-text
      >
      <v-row class="searchMap" no-gutters>
       
      
          <v-btn 
          @click="searchBtn=false"
          class="btn white teal--text">
            <v-icon> mdi-magnify </v-icon>
            Rentrer votre adresse
          </v-btn >
     
     
     
       
          <v-btn @click="navMap" class="btn white teal--text"> 
               <v-icon> mdi-earth </v-icon>
              Voir la carte </v-btn>
          <v-btn @click="navMapGeo" class="btn white teal--text"> 
               <v-icon> mdi-google-maps </v-icon>
              Se géolocaliser </v-btn>
      </v-row>
    </v-card>
      </div>

      <div v-if="searchBtn ==  false">
          
        
         <v-card  class="searcMapVcard">
          <v-btn 
          @click="searchBtn = true"
          class="btn white teal--text">Retour</v-btn>
                <v-card-title class="text-h5 white--text justify-center">Recherche dans la carte</v-card-title>
        
        <v-row>
            <v-col>
        <VueGoogleAutocomplete
                class="teal--text textfield"
            ref="address"
            id="map"
            classname="form-control"
            placeholder="Please type your address"
            v-on:placechanged="getAddressData"
            country="fr"
        >
        </VueGoogleAutocomplete>
            </v-col>
        </v-row>
            <v-row>
            <v-col>
      <v-btn
        @click="navMap"
       class="btn white teal--text">rechercher</v-btn>
         </v-col>
        </v-row>
      
        
    
    </v-card>
          
      </div>


  </div>
</template>

<script>

   import VueGoogleAutocomplete from 'vue-google-autocomplete'
export default {
     components: { VueGoogleAutocomplete },
    data(){
        return {
            searchBtn : true,
             address: ''
        }
    },
    mounted() {
            // To demonstrate functionality of exposed component functions
            // Here we make focus on the user input
        //    this.$refs.address.focus();
        },

        methods: {
            navMap(){
                this.$router.push({name:'Map',params:{id:123}})
            },
            navMapGeo(){
                this.$router.push({name:'Map',params:{id:"geolocated"}})
            },
            /**
            * When the location found
            * @param {Object} addressData Data of the found location
            * @param {Object} placeResultData PlaceResult object
            * @param {String} id Input container ID
            */
            getAddressData: function (addressData, placeResultData, id) {
                this.address = addressData;
              
                
                this.$store.commit('GET_ADRESS_GEOLOCATE',this.address)
                console.log(this.$store.state.addressGeolocate)
            }
        }
};
</script>
<style>

.searchMapDiv {
     background: url("../assets/veloHome.mobile.png");
     height:100%;
     position:relative;
     text-align: center;
    
}
.searcMapVcard {
  padding: 10px;
  position:absolute;
  box-shadow: none !important;
  background: transparent!important;
  width:100%
 
}


.searchMap {
  align-items: baseline;
  margin: 9px 15px;
}

.btn {
        font-size: 12px!important;
    margin: 5px 4px;
}

.textfield {
    padding: 13px 16px;
    background-color: white;
    border-radius: 39px;
    color:teal
}

input , label {
    background-color:white!important;
    width: 100%;
    
    font-size: 12px;
    

}



</style>